@import "variables";
header {
  &.main__header {
    .header-top {
      background-color: $color_primary;
      padding: 10px 0;

      .header-top-center {
        @media (max-width: 767.98px) {
          padding: 10px 0;
        }
      }

      .header-top-start {
        display: flex;
        align-items: center;
        gap: 6px;

        @media (max-width: 767.98px) {
          display: none;
        }

        .support-bock-content {
          span {
            color: $color_white;
            font-size: 12px;
            font-weight: 400;
          }

          h5 {
            color: $color_white;
            font-size: 20px;
            font-weight: 400;
          }
        }

        img {
          @media (max-width: 767.98px) {
            width: 120px;
          }
        }
      }

      .header-top-end {
        ul {
          display: flex;
          align-items: center;
          gap: 30px;
          justify-content: flex-end;

          li {
            a {
              position: relative;

              .badge {
                position: absolute;
                top: -8px;
                right: -9px;
                background-color: $color_secondary;
                border-radius: 50%;
                color: #000;
                height: 23px;
                width: 23px;
                line-height: 23px;
                text-align: center;
                padding: 0;
              }
            }
          }
        }
      }

      .search_icon {
        position: absolute;
        left: 12px;
      }

      .form-control {
        background-color: $color_white;
        border-radius: 5px 0 0 5px;
        border: 0;
        padding: 15px 15px 15px 60px;
        color: #999999;
        font-size: 15px;
        font-weight: 500;
      }

      .btn {
        &.btn-search {
          background-color: $color_secondary;
          color: $color_black;
          border: 0;
          padding: 15px 30px;
          border-radius: 0 5px 5px 0;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .header-bottom {
      background-color: #eeeeee;

      .header-bottom-start {
        position: relative;

        .navbar {
          padding: 0;
          .navbar-nav {
            gap: 20px;

            .nav-item {
              .nav-link {
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $color_black;
              }
            }
          }
        }
      }
      .header-bottom-end {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        ul {
          @media (max-width: 767.98px) {
            display: none;
          }
        }

        .navbar-nav {
          gap: 20px;
          flex-direction: row;

          .nav-item {
            .nav-link {
              padding: 0;
              font-size: 16px;
              font-weight: 500;
              text-transform: uppercase;
              color: $color_black;
            }
          }
        }

        .today-rate {
          background-color: #d5a83e;
          color: $color_black;
          padding: 15px 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 10px;
            font-weight: 800;
          }

          h5 {
            font-size: 15px;
            font-weight: 800;
            line-height: 10px;
          }
        }
      }
    }
  }
}

/*----------------------------------------*/
/*  4.1 Main menu css
  /*----------------------------------------*/
.main-menu > nav > ul > li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 27px;
}
.main-menu > nav > ul > li.has-mega-menu {
  position: static;
}
.main-menu > nav > ul > li > a {
  display: inline-block;
  font-size: 16px;
  color: var(--tp-common-black);
  padding: 23px 0;
}
.main-menu > nav > ul > li.has-dropdown > a {
  position: relative;
}
.main-menu > nav > ul > li.has-dropdown > a::after {
  content: "\f107";
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -o-transform: translateY(1px);
  transform: translateY(1px);
  font-size: 14px;
  color: #000;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  margin-left: 5px;
  display: inline-block;
}
.main-menu > nav > ul > li > .tp-submenu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background: var(--tp-common-white);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  padding: 12px 25px 20px;
  transform-origin: top;
  transition-duration: 0.1s;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.main-menu > nav > ul > li > .tp-submenu li {
  display: block;
  width: 100%;
  margin: 0;
}
.main-menu > nav > ul > li > .tp-submenu li:not(:last-child) {
  margin-right: 0;
}
.main-menu > nav > ul > li > .tp-submenu li.has-dropdown > a::after {
  position: absolute;
  right: 0;
  content: "\f107";
  font-size: 14px;
  color: var(--tp-common-black);
  font-family: var(--tp-ff-fontawesome);
  font-weight: 400;
  margin-left: 5px;
  display: inline-block;
  -webkit-transform: translateY(0%) rotate(-90deg);
  -moz-transform: translateY(0%) rotate(-90deg);
  -ms-transform: translateY(0%) rotate(-90deg);
  -o-transform: translateY(0%) rotate(-90deg);
  transform: translateY(0%) rotate(-90deg);
}
.main-menu > nav > ul > li > .tp-submenu li a {
  padding: 3px 0;
  font-size: 14px;
  position: relative;
  z-index: 1;
  color: var(--tp-text-body);
  width: 100%;
  display: block;
}
.main-menu > nav > ul > li > .tp-submenu li > .tp-submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.main-menu > nav > ul > li > .tp-submenu li:hover > a {
  color: var(--tp-theme-primary);
}
.main-menu > nav > ul > li > .tp-submenu li:hover > a::after {
  color: var(--tp-theme-primary);
}
.main-menu > nav > ul > li .tp-mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #eeeeee;
  box-shadow: 0px 20px 30px rgba(1, 15, 28, 0.1);
  z-index: 9;
  padding: 20px 20px 0;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.1s;
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.main-menu > nav > ul > li .tp-mega-menu.mega-menu-style-2 {
  padding: 25px 40px 0;
}
.main-menu > nav > ul > li .tp-mega-menu li {
  margin-bottom: 35px;
  float: left;
  width: 25%;
  overflow: hidden;
}
.main-menu > nav > ul > li .tp-mega-menu li.has-dropdown > a::after {
  display: none;
}
.main-menu > nav > ul > li .tp-mega-menu li .mega-menu-title {
  font-size: 16px;
  color: var(--tp-common-black);
  display: block;
  margin-bottom: 7px;
}
.main-menu > nav > ul > li .tp-mega-menu ul li {
  float: none;
  width: 100%;
  line-height: 1;
  margin-bottom: 0;
}
.main-menu > nav > ul > li .tp-mega-menu ul li:not(:last-child) {
  margin-bottom: 9px;
}
.main-menu > nav > ul > li .tp-mega-menu .tp-submenu {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.main-menu > nav > ul > li:hover > a {
  color: var(--tp-theme-primary);
}
.main-menu > nav > ul > li:hover > a::after {
  color: var(--tp-theme-primary);
}
.main-menu > nav > ul > li:hover > .tp-submenu {
  visibility: visible;
  opacity: 1;
  transition-duration: 0.2s;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}
.main-menu > nav > ul > li:hover > .tp-mega-menu {
  visibility: visible;
  opacity: 1;
  transition-duration: 0.2s;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}
.main-menu > nav > ul > li:hover > .tp-mega-menu .tp-submenu {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.main-menu.menu-style-1 {
  padding-left: 7px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 576px) and (max-width: 767px),
  (max-width: 575px) {
  .main-menu.menu-style-1 {
    padding-left: 0;
    margin-right: -40px;
  }
}
.main-menu.menu-style-1 > nav > ul > li {
  display: inline-block;
}
.main-menu.menu-style-1 > nav > ul > li:not(:last-child) {
  margin-right: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-style-1 > nav > ul > li:not(:last-child) {
    margin-right: 20px;
  }
}
.main-menu.menu-style-1 > nav > ul > li > a {
  font-weight: 500;
  font-size: 14px;
  color: var(--tp-common-black);
  padding: 11px 0;
}
.main-menu.menu-style-1 > nav > ul > li:hover > a {
  color: var(--tp-theme-primary);
}
.main-menu.menu-style-2 {
  margin-left: -30px;
}
.main-menu.menu-style-2 > nav > ul > li {
  margin-right: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu.menu-style-2 > nav > ul > li {
    margin-right: 15px;
  }
}
.main-menu.menu-style-2 > nav > ul > li > a {
  padding: 22px 0;
}
.main-menu.menu-style-2 > nav > ul > li:hover > a {
  color: var(--tp-theme-secondary);
}
.main-menu.menu-style-2 > nav > ul > li:hover > a::after {
  color: var(--tp-theme-secondary);
}
.main-menu.menu-style-2 > nav > ul > li .tp-submenu li:hover > a {
  color: var(--tp-theme-secondary);
}
.main-menu.menu-style-2 > nav > ul > li .tp-submenu li:hover > a::after {
  color: var(--tp-theme-secondary);
}
.main-menu.menu-style-2 > nav > ul > li .home-menu-title a:hover {
  color: var(--tp-theme-secondary);
}
.main-menu.menu-style-2 > nav > ul > li .home-menu-item:hover .home-menu-title {
  color: var(--tp-theme-secondary);
}
.main-menu.menu-style-2 .tp-menu-showcase-btn-2 {
  border-radius: 0;
  background-color: var(--tp-theme-secondary);
}
.main-menu.menu-style-3 > nav > ul > li {
  text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu.menu-style-3 > nav > ul > li {
    margin-right: 20px;
  }
}
.main-menu.menu-style-3 > nav > ul > li > a {
  padding: 17px 0;
  color: var(--tp-common-white);
}
.main-menu.menu-style-3 > nav > ul > li.has-dropdown > a::after {
  color: var(--tp-common-white);
}
.main-menu.menu-style-3 > nav > ul > li.tp-menu-line {
  position: absolute !important;
  margin: 0;
  text-align: initial;
  display: block;
}
.main-menu.menu-style-3 > nav > ul > li:hover > a {
  color: var(--tp-common-white);
}
.main-menu.menu-style-3 > nav > ul > li:hover > a::after {
  color: var(--tp-common-white);
}
.main-menu.menu-style-3 > nav > ul > li .home-menu-title a:hover {
  color: var(--tp-theme-brown);
}
.main-menu.menu-style-3 > nav > ul > li .home-menu-item:hover .home-menu-title {
  color: var(--tp-theme-brown);
}
.main-menu.menu-style-3 > nav > ul > li .tp-submenu {
  text-align: left;
}
.main-menu.menu-style-3 > nav > ul > li .tp-submenu li:hover > a {
  color: var(--tp-theme-brown);
}
.main-menu.menu-style-3 > nav > ul > li .tp-submenu li:hover > a::after {
  color: var(--tp-theme-brown);
}
.main-menu.menu-style-3 .tp-menu-showcase-btn-2 {
  border-radius: 0;
  background-color: var(--tp-theme-brown);
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li {
  text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu.menu-style-3.menu-style-4 > nav > ul > li {
    margin-right: 20px;
  }
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li > a {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li.has-dropdown > a::after {
  color: rgba(255, 255, 255, 0.8);
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li:hover > a {
  color: var(--tp-common-white);
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li:hover > a::after {
  color: var(--tp-common-white);
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li .tp-submenu {
  text-align: left;
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li .tp-submenu li a {
  color: var(--tp-common-black);
}
.main-menu.menu-style-3.menu-style-4
  > nav
  > ul
  > li
  .tp-submenu
  li.has-dropdown
  > a::after {
  color: var(--tp-common-black);
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li .tp-submenu li:hover > a {
  color: var(--tp-theme-brown);
}
.main-menu.menu-style-3.menu-style-4
  > nav
  > ul
  > li
  .tp-submenu
  li:hover
  > a::after {
  color: var(--tp-theme-brown);
}

h3.mega-menu-title {
  font-size: 15px;
  font-weight: 600;
  color: #6d6d6d;
  margin-bottom: 20px;
}

.mega-dropdown-menu {
  ul {
    li {
      a {
        color: $color_black;
        font-size: 15px;
        font-weight: 500;
        line-height: 30px;
        display: inline-block;
      }
    }
  }
}

.col-4.col-md-3.order-1.order-md-1 {
  @media (max-width: 767.98px) {
    display: none;
  }
}

a {
  &.dropdown-toggle {
    color: $color_black;
    font-weight: 500;
    font-size: 14px;
  }
}

.navbar-collapse {
  justify-content: space-between;
}

#search {
  align-items: center;
  background: rgba(0, 0, 0, 0.975);
  height: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  position: fixed;
  transition: all 0.5s;
  width: 100vw;
  will-change: transform, opacity;
  z-index: -1;
  margin-top: 89px;
}
#search:target {
  height: 20vh !important;
  opacity: 1;
  width: 100vw !important;
  z-index: 9999;

  @media (max-width: 575.98px) {
    height: 15vh !important;
  }
}
#search:target .close-btn {
  display: block;
}
#searchbox {
  background: transparent;
  border: solid #fff;
  border-width: 0 0 1px 0;
  color: #fff;
  flex: 1 0 auto;
  font-size: 2rem;
  height: 2rem;
  max-width: 90%;
  outline: 0;
  padding: 2rem 1rem;
}
.close-btn {
  display: none;
  color: #fff;
  font-size: 2rem;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
}
#search.left,
#nav ul.left {
  left: 0;
  height: 100vh;
  width: 0;
}
#search.right,
#nav ul.right {
  height: 100vh;
  width: 0;
  right: 0;
  left: auto !important;
}
#search.bottom.left,
#nav ul.bottom.left,
#search.bottom.right,
#nav ul.bottom.right,
#search.top.left,
#nav ul.top.left,
#search.top.right,
#nav ul.top.right {
  height: 0;
}
#search.bottom,
#nav ul.bottom {
  bottom: 0;
  top: auto !important;
}
#search.fade,
#nav ul.fade {
  height: 100vh;
  width: 100vw;
  transition: opacity 0.5s;
}

/*----------------------------------------*/
/*  4.2 Meanmenu css
  /*----------------------------------------*/
.mean-remove {
  display: none !important;
}

.mean-container {
  margin-bottom: 40px;
}
.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  font-weight: 700;
  display: none !important;
}
.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  height: 3px;
  margin-top: 3px;
}
.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}
.mean-container .mean-nav {
  background: none;
  margin-top: 0;
  float: left;
  width: 100%;
  background: transparent;
}
.mean-container .mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}
.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}
.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}
.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}
.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}
.mean-container .mean-nav ul li.dropdown-opened > a,
.mean-container .mean-nav ul li.dropdown-opened > span {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li.dropdown-opened > a.mean-expand.mean-clicked,
.mean-container
  .mean-nav
  ul
  li.dropdown-opened
  > span.mean-expand.mean-clicked {
  color: var(--tp-theme-primary);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mean-container .mean-nav ul li.dropdown-opened > a.mean-expand.mean-clicked i,
.mean-container
  .mean-nav
  ul
  li.dropdown-opened
  > span.mean-expand.mean-clicked
  i {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li.has-dropdown:hover > a {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li.has-dropdown:hover .mean-expand {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li.mean-last {
  border-bottom: none;
  margin-bottom: 0;
}
.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
}
.mean-container .mean-nav ul li > a > i {
  display: none;
}
.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 10px 5%;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #e0e3ed;
  text-decoration: none;
  width: 100%;
  padding: 10px 0;
  color: var(--tp-common-black);
  border-top: 1px solid #ebebeb;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}
[dir="rtl"] .mean-container .mean-nav ul li a {
  float: right;
  text-align: right;
}
.mean-container .mean-nav ul li a:hover {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li a:hover i {
  color: var(--tp-common-white);
}
.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 1px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: transparent;
  border: none !important;
  font-size: 14px;
  margin-top: 5px;
  padding: 0 !important;
  line-height: 14px;
  height: 30px;
  width: auto;
  line-height: 30px;
  color: var(--tp-common-black);
  line-height: 30px;
  top: 0;
  font-weight: 400;
}
[dir="rtl"] .mean-container .mean-nav ul li a.mean-expand {
  right: auto;
  left: 0;
  text-align: center;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  color: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li a.mean-expand:hover i {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li a.mean-expand.mean-clicked:hover {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li a.mean-expand.mean-clicked:hover i {
  color: var(--tp-theme-primary);
}
.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 10px 10%;
  text-shadow: none !important;
  visibility: visible;
}
.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 10px 15%;
}
.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 10px 20%;
}
.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 10px 25%;
}
.mean-container .mean-bar,
.mean-container .mean-bar * {
  /* Fix for box sizing on Foundation Framework etc. */
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/*----------------------------------------*/
/*  4.3 Mobilemenu css
    /*----------------------------------------*/
.tp-mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--tp-common-white);
  padding: 13px 0 5px;
  z-index: 999;
  border-top: 1px solid var(--tp-border-primary);
}
.tp-mobile-menu.is-sticky {
  bottom: -120px;
  visibility: hidden;
  opacity: 0;
}
.tp-mobile-menu.is-sticky.bottom-menu-sticky {
  visibility: visible;
  opacity: 1;
  bottom: 0;
}
.tp-mobile-item-btn {
  font-size: 28px;
  text-align: center;
}
.tp-mobile-item-btn span {
  display: block;
  line-height: 1;
  font-size: 13px;
  margin-top: 3px;
}
.tp-mobile-item-btn:hover {
  color: var(--tp-common-black);
}

.tp-main-menu-mobile .tp-submenu {
  display: none;
}
.tp-main-menu-mobile .tp-mega-menu .shop-mega-menu-title {
  margin: 0;
  padding-top: 7px;
}
.tp-main-menu-mobile .tp-mega-menu.shop-mega-menu {
  padding: 0 !important;
  padding-left: 19px !important;
  padding-top: 10px !important;
}
.tp-main-menu-mobile .tp-mega-menu .shop-mega-menu-img {
  margin: 7px 0;
}
.tp-main-menu-mobile ul {
  position: static;
  display: block;
  box-shadow: none;
}
.tp-main-menu-mobile ul li {
  list-style: none;
  position: relative;
  width: 100%;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.tp-main-menu-mobile ul li:not(:last-child) a {
  border-bottom: 1px solid rgba(1, 15, 28, 0.1);
}
.tp-main-menu-mobile ul li.has-dropdown > a .dropdown-toggle-btn {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 16px;
  color: #7f8387;
  z-index: 1;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border: 1px solid rgba(1, 15, 28, 0.12);
  -webkit-transition: background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  -ms-transition: background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
}
.tp-main-menu-mobile
  ul
  li.has-dropdown
  > a
  .dropdown-toggle-btn.dropdown-opened
  i {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tp-main-menu-mobile ul li.has-dropdown > a .dropdown-toggle-btn:hover {
  background-color: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}
.tp-main-menu-mobile ul li.has-dropdown > a .dropdown-toggle-btn:hover i {
  color: var(--tp-common-white);
}
.tp-main-menu-mobile ul li.has-dropdown > a.expanded {
  color: var(--tp-theme-primary);
}
.tp-main-menu-mobile
  ul
  li.has-dropdown
  > a.expanded
  .dropdown-toggle-btn.dropdown-opened {
  background-color: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}
.tp-main-menu-mobile
  ul
  li.has-dropdown
  > a.expanded
  .dropdown-toggle-btn.dropdown-opened
  i {
  color: var(--tp-common-white);
}
.tp-main-menu-mobile ul li.has-dropdown:hover > a::after {
  color: var(--tp-theme-green);
}
.tp-main-menu-mobile ul li:last-child a span {
  border-bottom: 0;
}
.tp-main-menu-mobile ul li > a {
  display: block;
  font-size: 16px;
  color: var(--tp-common-black);
  position: relative;
  padding: 10px 0;
  padding-right: 20px;
}
.tp-main-menu-mobile ul li > a > i {
  display: inline-block;
  width: 11%;
  margin-right: 13px;
  -webkit-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -ms-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
  font-size: 21px;
  line-height: 1;
}
.tp-main-menu-mobile ul li > a .menu-text {
  font-size: 16px;
  line-height: 11px;
  border-bottom: 1px solid #eaebed;
  width: 82%;
  display: inline-block;
  padding: 19px 0 17px;
}
.tp-main-menu-mobile ul li img {
  width: 100%;
}
.tp-main-menu-mobile ul li ul {
  padding: 0;
}
.tp-main-menu-mobile ul li ul li {
  padding: 0;
}
.tp-main-menu-mobile ul li ul li a {
  margin-left: auto;
  width: 93%;
  padding: 10px 5%;
  text-shadow: none !important;
  visibility: visible;
  padding-left: 0;
  padding-right: 20px;
}
.tp-main-menu-mobile ul li ul li li a {
  width: 88%;
  padding: 10px 7%;
  padding-left: 0;
  padding-right: 20px;
}
.tp-main-menu-mobile ul li ul li li li a {
  width: 83%;
  padding: 10px 9%;
  padding-left: 0;
  padding-right: 20px;
}
.tp-main-menu-mobile ul li ul li li li li a {
  width: 68%;
  padding: 10px 11%;
  padding-left: 0;
  padding-right: 20px;
}
.tp-main-menu-mobile ul li:hover > a {
  color: var(--tp-theme-primary);
}
.tp-main-menu-mobile ul li:hover > a::after {
  color: var(--tp-theme-primary);
}
.tp-main-menu-mobile ul li:hover > a .dropdown-toggle-btn i {
  color: var(--tp-theme-primary);
}
.tp-main-menu-mobile ul li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  top: 0;
}
.tp-main-menu-mobile ul li .mega-menu,
.tp-main-menu-mobile ul li .submenu {
  position: static;
  min-width: 100%;
  padding: 0;
  box-shadow: none;
  visibility: visible;
  opacity: 1;
  display: none;
}
.tp-main-menu-mobile ul li .mega-menu li,
.tp-main-menu-mobile ul li .submenu li {
  float: none;
  display: block;
  width: 100%;
  padding: 0;
}
.tp-main-menu-mobile ul li .mega-menu li:hover a .dropdown-toggle-btn,
.tp-main-menu-mobile ul li .submenu li:hover a .dropdown-toggle-btn {
  color: var(--tp-theme-primary);
}
.tp-main-menu-mobile
  .tp-main-menu-content
  ul
  li:not(:last-child)
  .home-menu-title
  a {
  border-bottom: none;
}
.tp-main-menu-mobile * ul,
.tp-main-menu-mobile * li {
  transition: none !important;
}

nav.tp-main-menu-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-menu-title {
  text-align: start;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  padding: 15px 15px 0 0;
}

/* HEADER CSS */

.home-menu-item {
  text-align: center;
  margin-bottom: 22px;
}
.home-menu-item a {
  border: 0 !important;
  padding: 0 !important;
}
.home-menu-item:hover .home-menu-thumb {
  box-shadow: none;
}
.home-menu-item:hover .home-menu-thumb::after {
  opacity: 1;
  visibility: visible;
}
.home-menu-item:hover .home-menu-title {
  color: var(--tp-theme-primary);
}
.home-menu-item .menu-text {
  display: none !important;
}
.home-menu-thumb {
  position: relative;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px rgba(1, 15, 28, 0.2);
}
.home-menu-thumb img {
  -webkit-transition: transform 0.2s 0s ease-out;
  -moz-transition: transform 0.2s 0s ease-out;
  -ms-transition: transform 0.2s 0s ease-out;
  -o-transition: transform 0.2s 0s ease-out;
  transition: transform 0.2s 0s ease-out;
  max-width: 100%;
}

.home-menu-thumb img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.shop-mega-menu-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-menu-thumb::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  height: 94%;
  width: 90%;
  border: 1px solid #fff;
}
.home-menu-title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}
.home-menu-title a:hover {
  color: var(--tp-theme-primary);
}

.shop-mega-menu {
  padding: 30px 40px 0 !important;
  padding-bottom: 30px !important;
}
.shop-mega-menu-title {
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
  padding-bottom: 7px;
  margin-bottom: 15px;
  display: block;
  color: var(--tp-common-black);
}
.shop-mega-menu-img {
  position: relative;
  overflow: hidden;
  height: calc(100% - 30px);
}

.shop-mega-menu-img::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  height: 94%;
  width: 90%;
  border: 1px solid #fff;
}

.shop-mega-menu-img:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.shop-mega-menu-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shop-mega-menu-btn {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-align: center;
}

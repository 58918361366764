@import "variables";
body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  scroll-behavior: auto;
  font-family: $font_primary;
}

body,
html {
  height: 100%;
}

body a {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  text-decoration: none;
  outline: none;
}

img {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}

:hover {
  text-decoration: none;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

:focus {
  outline: none;
  text-decoration: none;
}

::selection {
  color: $color_white;
  background-color: $color_primary;
}

.px-7 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;

  @media (max-width: 767.98px) {
    padding-right: 0.9em !important;
    padding-left: 0.9em !important;
  }
}

.btn {
  &.btn-primary {
    background-color: $color_primary;
    color: #fff;
    border-radius: 0;
    border: 0;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    padding: 10px 20px 10px 40px;

    &::after {
      content: "";
      position: absolute;
      top: 13px;
      left: 15px;
      background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='15px' height='15px' viewBox='0 0 40.731 40.731' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M37.761,19.833c0.114,0.164,0.184,0.363,0.184,0.578c0,0.431-0.271,0.797-0.653,0.938 c-1.546,1.096-9.82,7.307-16.016,18.733c-0.122,0.304-0.378,0.548-0.7,0.621c-0.084,0.021-0.168,0.027-0.252,0.027 c-0.339,0-0.656-0.156-0.832-0.453c-0.047-0.07-0.084-0.146-0.112-0.228c-0.804-1.56-6.132-11.422-15.984-18.756 c-0.358-0.152-0.608-0.507-0.608-0.921c0-0.008,0-0.016,0-0.022c-0.004-0.19,0.045-0.381,0.149-0.549 c0.118-0.19,0.295-0.333,0.499-0.41c1.279-0.894,9.649-7.022,15.965-18.612C19.499,0.334,19.899,0,20.376,0 c0.196,0,0.379,0.057,0.533,0.154c0.213,0.12,0.376,0.316,0.455,0.553c0.613,1.209,5.938,11.318,16.04,18.802 C37.548,19.587,37.672,19.698,37.761,19.833z'/%3E%3C/g%3E%3C/svg%3E");
      height: 15px;
      width: 15px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &.btn-all {
    font-size: 15px;
    font-weight: 400;
    color: $color_black;
    text-transform: uppercase;
    position: relative;
    padding: 10px 0px 10px 35px;

    &::after {
      content: "";
      position: absolute;
      top: 13px;
      left: 15px;
      background-image: url("data:image/svg+xml,%3Csvg fill='%23000' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='15px' height='15px' viewBox='0 0 40.731 40.731' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M37.761,19.833c0.114,0.164,0.184,0.363,0.184,0.578c0,0.431-0.271,0.797-0.653,0.938 c-1.546,1.096-9.82,7.307-16.016,18.733c-0.122,0.304-0.378,0.548-0.7,0.621c-0.084,0.021-0.168,0.027-0.252,0.027 c-0.339,0-0.656-0.156-0.832-0.453c-0.047-0.07-0.084-0.146-0.112-0.228c-0.804-1.56-6.132-11.422-15.984-18.756 c-0.358-0.152-0.608-0.507-0.608-0.921c0-0.008,0-0.016,0-0.022c-0.004-0.19,0.045-0.381,0.149-0.549 c0.118-0.19,0.295-0.333,0.499-0.41c1.279-0.894,9.649-7.022,15.965-18.612C19.499,0.334,19.899,0,20.376,0 c0.196,0,0.379,0.057,0.533,0.154c0.213,0.12,0.376,0.316,0.455,0.553c0.613,1.209,5.938,11.318,16.04,18.802 C37.548,19.587,37.672,19.698,37.761,19.833z'/%3E%3C/g%3E%3C/svg%3E");
      height: 15px;
      width: 15px;
    }
  }

  &.btn-cart {
    background-color: $color_primary;
    color: #fff;
    border-radius: 6px;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    padding: 15px 100px;
  }

  &.btn-login {
    background-color: $color_primary;
    color: #fff;
    border-radius: 0;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    padding: 15px 20px;
    border-radius: 6px;

    &:hover {
      background-color: #2b5940;
    }
  }

  &.btn-edit {
    background-color: $color_white;
    color: $color_primary;
    border-radius: 0;
    border: 2px solid #dbdddf;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    padding: 8px 10px;
    border-radius: 6px;

    &:hover {
      background-color: #2b5940;
      color: $color_white;
      border-color: $color_primary;
    }
  }

  &.btn-password {
    background-color: $color_white;
    color: $color_secondary;
    border-radius: 0;
    border: 2px solid $color_secondary;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    padding: 16px 10px;
    border-radius: 6px;

    &:hover {
      background-color: #2b5940;
      color: $color_white;
      border-color: $color_primary;
    }
  }

  &.btn-password {
    background-color: $color_white;
    color: $color_secondary;
    border-radius: 0;
    border: 2px solid $color_secondary;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    padding: 16px 10px;
    border-radius: 6px;

    &:hover {
      background-color: $color_secondary;
      color: $color_white;
      border-color: $color_secondary;
    }
  }

  &.btn-delete {
    background-color: transparent;
    color: #f44336;
    border-radius: 0;
    border: 0;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    text-decoration: underline;
  }

  &.btn-add-cart {
    background-color: $color_primary;
    font-size: 15px;
    font-weight: 400;
    color: $color_white;
    position: relative;
    padding: 10px;
  }

  &.btn-view {
    background-color: $color_secondary;
    font-size: 12px;
    font-weight: 400;
    color: $color_white;
    text-transform: uppercase;
    position: relative;
    padding: 6px 12px 6px 30px;
    z-index: 999;
    border-radius: 0;

    &::after {
      content: "";
      position: absolute;
      top: 6px;
      left: 15px;
      background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='15px' height='15px' viewBox='0 0 40.731 40.731' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M37.761,19.833c0.114,0.164,0.184,0.363,0.184,0.578c0,0.431-0.271,0.797-0.653,0.938 c-1.546,1.096-9.82,7.307-16.016,18.733c-0.122,0.304-0.378,0.548-0.7,0.621c-0.084,0.021-0.168,0.027-0.252,0.027 c-0.339,0-0.656-0.156-0.832-0.453c-0.047-0.07-0.084-0.146-0.112-0.228c-0.804-1.56-6.132-11.422-15.984-18.756 c-0.358-0.152-0.608-0.507-0.608-0.921c0-0.008,0-0.016,0-0.022c-0.004-0.19,0.045-0.381,0.149-0.549 c0.118-0.19,0.295-0.333,0.499-0.41c1.279-0.894,9.649-7.022,15.965-18.612C19.499,0.334,19.899,0,20.376,0 c0.196,0,0.379,0.057,0.533,0.154c0.213,0.12,0.376,0.316,0.455,0.553c0.613,1.209,5.938,11.318,16.04,18.802 C37.548,19.587,37.672,19.698,37.761,19.833z'/%3E%3C/g%3E%3C/svg%3E");
      height: 15px;
      width: 15px;
    }
  }

  &.btn-checkout {
    background-color: transparent;
    color: $color_primary;
    border-radius: 0;
    border: 1px solid $color_primary;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    padding: 15px 20px;
    border-radius: 6px;

    &:hover {
      background-color: #2b5940;
    }
  }

  &.btn-find {
    background-color: $color_primary;
    color: $color_white;
    border-radius: 0;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    padding: 20px;
    border-radius: 0;
    text-transform: uppercase;
  }
}

h3 {
  &.sub-title {
    font-size: 25px;
    font-weight: 300;
    color: #3ca78b;
    text-transform: uppercase;
    @media (max-width: 767.98px) {
      font-size: 20px;
    }
  }
}

h4 {
  &.main-title {
    font-size: 35px;
    font-weight: 500;
    color: $color_primary;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    @media (max-width: 767.98px) {
      font-size: 26px;
    }
  }
}

.product-card {
  background-color: $color_white;
  border: 1px solid #dddddd;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  position: relative;

  img {
    border-radius: 10px;
  }

  .product-like-icon {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
  }

  .product-add-cart-btn-wrapper {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 99;

    -webkit-transition: all 0.3s 0s ease-out;
    -moz-transition: all 0.3s 0s ease-out;
    -ms-transition: all 0.3s 0s ease-out;
    -o-transition: all 0.3s 0s ease-out;
    transition: all 0.3s 0s ease-out;
  }

  &:hover {
    .product-add-cart-btn-wrapper {
      bottom: 20px;
      visibility: visible;
      opacity: 1;
    }

    img {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .bi {
    color: #000;
  }
}

a.product-title {
  font-size: 15px;
  font-weight: 400;
  color: $color_black;
  z-index: 99;
  position: relative;
}

h4.product-price {
  font-size: 15px;
  font-weight: 400;
  color: $color_black;
  margin-top: 5px;
}
